import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import featurestyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";

import WriteHere from '../SubSections/WriteHere.jsx'
import FormatHere from '../SubSections/FormatHere.jsx'
import EditHere from '../SubSections/EditHere.jsx'


class FeaturesSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer id='features' justify="center" >
          <GridItem > 
            <h2 className={classes.title}>Features</h2>
            <h5 className={classes.description}>
              This is where the features for Book Merlin will go.
            </h5>
          </GridItem>
          <GridItem >
            <div >
              <WriteHere />
              <EditHere />
              <FormatHere />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(featurestyle)(FeaturesSection);
 